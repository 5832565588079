@import url("./fonts.css");
@import url("./colors.css");
@import url("./auth.css");
@import url("./default-input.css");
@import url("./top-nav.css");
@import url("./side-nav.css");

@import url("./modal.css");
@import url("./youtube-embed.css");

@import url("./dashboard/home.css");
@import url("./dashboard/account-management.css");
@import url("./dashboard/footer.css");
@import url("./dashboard/application.css");
@import url("./dashboard/custom-select.css");
@import url("./dashboard.css");
@import url("./dashboard/messages.css");
@import url("./megaloader.css");

@import url("./small/SmallAuth.css");
@import url("./small/SmallHome.css");
@import url("./small/SmallTopNav.css");
@import url("./small/SmallApplication.css");
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
  outline: none !important;
}
.width-100 {
  width: 100%;
}
.flex-row {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}
.justify-between {
  justify-content: space-between;
}
.justify-center {
  justify-content: center;
}
.align-center {
  align-items: center;
}
.align-end {
  align-items: flex-end;
}
.pointer {
  cursor: pointer;
}

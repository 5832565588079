.fw-300 {
  font-weight: 300 !important;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800 !important;
}
.fw-900 {
  font-weight: 900 !important;
}

.px-10 {
  font-size: 10px;
}
.px-11 {
  font-size: 11px;
}
.px-12 {
  font-size: 12px;
}
.px-13 {
  font-size: 13px;
}
.px-14 {
  font-size: 14px;
}
.px-15 {
  font-size: 15px;
}
.px-16 {
  font-size: 16px;
}
.px-17 {
  font-size: 17px;
}
.px-18 {
  font-size: 18px;
}
.px-19 {
  font-size: 19px;
}
.px-20 {
  font-size: 20px;
}
.px-21 {
  font-size: 21px;
}
.px-22 {
  font-size: 22px;
}
.px-23 {
  font-size: 23px;
}
.px-24 {
  font-size: 24px;
}
.px-25 {
  font-size: 25px;
}
.px-26 {
  font-size: 26px;
}
.px-27 {
  font-size: 27px;
}
.px-28 {
  font-size: 28px;
}
.px-29 {
  font-size: 29px;
}
.px-30 {
  font-size: 30px;
}
.px-31 {
  font-size: 31px;
}
.px-32 {
  font-size: 32px;
}
.px-33 {
  font-size: 33px;
}
.px-34 {
  font-size: 34px;
}
.px-35 {
  font-size: 35px;
}

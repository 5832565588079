:root {
  --pink-default: #F9E9FF;

  --blue-default: #A27AB3;

  --gray-default: #9a9ab0;
  --gray-primary: #aeaeae;
  --gray-secondary: #797979;
  --gray-tertiary: #d3d3d3;

  --purple-default: #40189d;
  --purple-primary: #f3eaff;
  --purple-another: #6e00ff;

  --purple-secondary: #cecce4;

  --black-default: #3d3d3d;

  --red-default: #9d1818;
}
.white-text {
  color: white;
}
.gray-text {
  color: var(--gray-default);
}
.gray-primary-text {
  color: var(--gray-primary);
}
.gray-secondary-text {
  color: var(--gray-secondary);
}
.black-default-text {
  color: var(--black-default);
}
.purple-default-text {
  color: var(--purple-default);
}

a {
  text-decoration: none;
}

.application-container {
  padding-top: 100px;
}
.surrogate-form-container {
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  margin: 40px 0;
  align-items: center;
}
.surrogate-form-left {
  border: 1px solid #e2e4e5;
  border-radius: 10px;
  padding: 30px;
  width: 70%;
}
.surrogate-form-right {
  height: fit-content;
}
.surrogate-form-about {
  max-width: 500px;
}
.surrogate-form-input {
  justify-content: space-between;
  margin: 10px 0;
  max-width: 500px;
}
.surrogate-form-label {
  margin-bottom: 5px;
}
.submit-application-form {
  background-color: var(--purple-secondary);
  width: fit-content;
  padding: 10px 30px;
  margin-top: 20px;
  border-radius: 6px;
}
.form-select-row {
  margin: 10px 0;
}

.toggle-topnav {
  display: none;
}
.top-nav-container {
  position: absolute;
  top: 0;
  right: 0;
  box-sizing: border-box;
  padding: 30px 20px;
}
.top-nav-button {
  justify-content: space-between;
  margin: 0 20px;
}
.top-nav-icon {
  margin-right: 3px;
  height: 25px;
  object-fit: contain;
}
.top-nav-count {
  background-color: var(--purple-default);
  color: #fff;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  box-sizing: border-box;
  border-radius: 50%;
  margin-top: -10px;
  box-shadow: 0px 6px 8px rgba(135, 67, 223, 0.37);
  font-family: "Poppins", sans-serif;
  font-size: 13px;
}

.top-nav-right {
  align-items: center;
}
.top-nav-avatar {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  margin-right: 10px;
}
.top-nav-col {
  font-size: 12px;
}
.top-nav-name {
  font-weight: 500;
  font-size: 16px;
  text-transform: capitalize;
}
.top-nav-links {
  align-items: flex-end;
  justify-content: space-between;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}
.top-nav-link {
  text-decoration: underline;
  cursor: pointer;
}
.top-nav-tag {
  color: var(--gray-default);
}

@import url("./modal/surrogate-reports.css");
.default-modal-container {
  align-items: center;
  justify-content: center;
  height: 100%;
  outline: none;
}
.default-modal-content {
  background-color: var(--purple-secondary);
  border-radius: 30px;
}
.profile-modal-container {
  background-color: white !important;
}
.modal-scrollbar {
  padding-right: 10px;
}
.modal-scrollbar::-webkit-scrollbar {
  width: 6px;
}
.modal-input-row {
  width: 100%;
  margin: 10px 0;
}
.modal-input-full {
  width: 100%;
}
.modal-input-half {
  width: 47%;
}
.css-1x51dt5-MuiInputBase-input-MuiInput-input.Mui-disabled {
  color: rgba(28, 28, 28, 0.8) !important;
  -webkit-text-fill-color: rgba(28, 28, 28, 0.8) !important;
}
/* Track */
.modal-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle */
.modal-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

/* Handle on hover */
.modal-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@media screen and (max-width: 550px) {
  div.modal-input-row {
    flex-direction: column;
    margin: 0 !important;
  }
  .modal-input-half {
    margin: 10px 0 !important;
    width: 100% !important;
  }
}
.modal-image-hide {
  visibility: hidden;
  z-index: -999999;
  position: absolute;
  bottom: 0;
  opacity: 0;
}

.modal-form-file-container {
  width: 100%;
  margin-bottom: 20px;
}
.modal-form-file {
  align-items: center;
  justify-content: space-between;
  border: 2px solid var(--gray-default);
  border-radius: 15px;
  height: 45px;
  padding: 5px 7px 5px 10px;
  margin: 5px 0;
}
.modal-form-file-btn {
  height: 100%;
  background-color: var(--purple-secondary);
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border-radius: 10px;
  border: 1px solid transparent;
}
.modal-form-file-about {
  color: rgba(189, 184, 184, 1);
}
